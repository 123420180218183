/* Global CSS  */

body {
    margin: 0;
    font-family: "Nanum Myeongjo", serif;
    -webkit-font-smoothing: "Nanum Myeongjo", serif;
    -moz-osx-font-smoothing: "Nanum Myeongjo", serif;
    font-style: normal;
}
 
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

:root {
    --primary: #ee99a0;;
    --heading-color: #282938;
    --bg-shade: #f5fcff;
    --black: #000000;
    --pink: #ee99a0;
    --white: #ffffff;

}

.btn {
    font-family: "Nanum Myeongjo", serif;
    display: inline-block;
    padding: 14px 32px;
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    line-height: 4vh;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: background-color 0.5s;
    -webkit-border-transition: 0.5s;
    -moz-border-transition: 0.5s;
    -ms-border-transition: 0.5s;
    -o-border-transition: 0.5s;
}

.btn-outline-primary {
    color: var(--white);
    background-color: #28293800;

    border: 1px solid var(--primary);
}

.btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);
}

.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.btn-primary:hover {
    color: var(--primary);
    background-color: var(--white);
}

/* Header Section */

.header--content {
    margin-top: none;
    display :flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height : auto;
    max-height: 100vh;
    width: auto;
    min-width: 100%;
    overflow: hidden;
    background-color: #000000;
}

.header--image--box {
    width: 100%;
    position: relative;
}

.header--image {
    opacity: 0.7;
    display: block;
    width : 100%;
    min-height: 100%;
    height: auto;
    min-width: 100%;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
}

.header--text {
    text-align: center;
    position: absolute;
    font-family: 'Petit Formal Script', cursive; 
    color: var(--white);
    margin-left: 20vh;
    margin-right: 20vh;

}

.header--text span{
    color: white;
    font-family: 'Petit Formal Script', cursive; 
    font-size:  10vw;
    font-weight : 400;
    letter-spacing: none;  
    padding: none; 
}

/* End of header of section */


/* Hero Section */
.hero--section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 8vh;
    padding-bottom: 8vh;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-shade);
}

.hero--section--content--box {
    padding-left: 10vw;
    padding-right: 10vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.hero--section--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21.333px;
}

.hero--section--title {
    color: var(--heading-color);
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    line-height: 10px;
    align-self: stretch;
    --mobile-base-font-size: 23;
    --mobile-font-size-scale: 1.15;

    
}

.hero--section--description {
    color: var(--darkblue);
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    --mobile-base-font-size: 23;
    --mobile-font-size-scale: 1.15;
}

/* End of Hero Section */

/* Navbar */


/* Navbar Style Start */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh 50px;
    background: #28293800;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    transition: background-color 0.3s , box-shadow 0.3s ease; 
  }
  .navbar--items > ul {
    right: 50%;
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 5vw;
    z-index: 100000;
    text-decoration: none;
  }
  .navbar--items ul > li > a {
    text-decoration: none;
  }
  /* Navbar Content */
  .navbar--content {
    color: var(--white);
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    line-height: 2vh;
    cursor: pointer;
  }
  .navbar--active-content {
    color: var(--white);
  }

  .nav--logo--image {
    width: 10vh;
    height:10vh;
    object-fit:cover;
  }

  .navbar.visible {
    display: flex;
    opacity: 1;
    /* Add other styles for visible state */
  }
  
  /* CSS for hidden state */
  .navbar.hidden {
    display: none;
    opacity: 0;
    /* Add other styles for hidden state */
  }

  /* Add styles for the scrolled state */
    .navbar.scrolled {
    background-color: var(--primary);/* Your desired background color */;
    
    box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.708);
  }
  .navbar.solid-color {
    background-color: var(--primary); 
    box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.708);
  }
  .navbar .btn-outline-primary {
    font-size: 3vh;
    line-height: 3vh;
  }


  .nav__hamburger-dropdown {
    cursor: pointer;
  }
  
  
  @media only screen and (max-width: 1024px) {
    .feature1--description {
      display: flex;
      padding-top: 2vh;
      color: var(--black);
      font-family: "Nanum Myeongjo", serif;
      font-size: 1vw;
      font-variant: thin;
    }
    .navbar--items {
      display: none; /* Hide the normal navbar items */
    }
    .nav__hamburger {
        right: 8vw;
        display: none;
        position:absolute;
        cursor: pointer;
        height:auto;
        /* other styles for your hamburger icon */
        display: flex;
        flex-direction: column;
        justify-content: center; /* Add this line */
        align-items: center; /* Add this line */
      }
  
    .navbar .btn-outline-primary {
        display: none;
    }
    .nav__hamburger-dropdown__line{
      width:10vw;
      height:10vw;
    }
    .nav__hamburger__line {
      display: block;
      height: 2px;
      width: 10vw;
      background-color: #fff;
      margin: 5px 5px;
      z-index: 10000;
  }
    /* Add any additional styles for small screens as needed */
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: var(--primary);
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.395);
    width: 150px;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
    overflow-y: auto;
    transition: background-color 0.3s , box-shadow 0.3s ease; 
}
.dropdown-navbar-items {
  text-align: center ;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  gap: 8vh;
  color: var(--white);
  width: 25vw !important; 
  justify-content: center !important; 
  align-items: center !important; 
  margin-right:50%;
}
/* Navbar Styler Ends */
/* Navbar Dropdown */
/* Show the dropdown menu when dropdownVisible is true */
.dropdown-menu.active {

    transition: background-color 0.3s ease; 
    display: flex; /* Use flex to properly apply justify-content and align-items */
    justify-content: center;
    align-items: center;
}

/* Add styling for dropdown menu items */
.dropdown-menu a {
    color: var(--white);
    padding: 1vh 0; /* Adjust padding as needed */
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s;
}

.dropdown-menu a:hover { 
    background-color: #f0f0f0;
    color: #000000;
}

/* Feature 1 Stuff */
.feature1--content--box {
  /* padding-top: 5vh; */
    box-shadow: 5px 5px 5px, #000000;
    display: flex;
    align-items: center;
    background-color: #f3f2f2fd;
    max-height: 90vh;
    height:auto;
    overflow:hidden;
    /* padding-bottom: 5vh; */
  }

.feature1--photo--container {
flex: 1; /* Take up half of the container */
}
  
.feature1--image {
    display: block;
    width: 100%; /* Make sure the image takes the full width of the container */
}
  
.feature1--text--container {
    flex: 1; /* Take up the other half of the container */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center the text horizontally */
}
  

.feature1--title{ 
    color: var(--black);
    font-family: 'Petit Formal Script', cursive; 
    font-size: 5vw;
}

.feature1--price {
    padding-top: 2vh;
    color: var(--black);
    font-family: "Nanum Myeongjo", serif;
    font-size: 3vw;
    font-variant: thin;
}

.feature1--description {
  padding-top: 2vh;
    color: var(--black);
    font-family: "Nanum Myeongjo", serif;
    font-size: 3vw;
    font-variant: thin;
}
/* End of Feature 1 stuff */
.scrollable-marquee-container {
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 100%;
    overflow-x: auto;
  }
  
  .scrollable-marquee {
    display: inline-flex;
    gap: 20px;
    margin-bottom: 5vh;
  }
  .original--marque--title{
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 10vw;
    font-size: max(3vw, 3vh);
    font-weight: 100;
    font-family: 'Petit Formal Script', cursive; 
  }
  .marquee-item {
    width: 100%; /* Adjust the width of each image */
    min-width: 100px; /* Set a minimum width */
    margin-right: 20px; 
    margin-left:20px;
    font-weight: 100;
    font-size: 1vw;
    color: var(--black);
    font-family: "Nanum Myeongjo", serif;
    line-height: 4vh;
    text-align: center;
    cursor: pointer;
  }
  
  .marquee-item img {
    object-fit:cover;
    width: 100%;
    min-width: 20vw;
    height: 25vw;
    /* Add additional styling as needed */
  }
  .marquee-item img:hover {
    
    opacity: 0.9;
    transition: opacity 0.3s ease;
  }

  @media only screen and (max-width: 768px) {
    .marquee-item img {
      width: 100%;
      min-width: 20vw;
      height: 25vh;
      /* Add additional styling as needed */
    }
    .marquee-item {
      width: 100%; /* Adjust the width of each image */
      min-width: 100px; /* Set a minimum width */
      margin-right: 20px; 
      margin-left:20px;
      font-weight: 100;
      text-decoration: none;
      font-size: 1vh;
      color: var(--black);
      font-family: "Nanum Myeongjo", serif;
      line-height: 4vh;
      text-align: center;
      cursor: pointer;
    }
  }

/* Feature 2 Stuff */

  
/* Feature 1 Stuff */
.feature2--content--box {
    display: flex;
    align-items: center;
    background-color: var(--white);
    height: 90vh;
    overflow: hidden;
  }

.feature2--photo--container {
flex: 1; /* Take up half of the container */
}
  
.feature2--image {
    display: block;
    width: 100%; /* Make sure the image takes the full width of the container */
}
  
.feature2--text--container {
    flex: 1; /* Take up the other half of the container */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center the text horizontally */
}
  

.feature2--title{ 
    padding-bottom: 2vh;
    color: var(--black);
    font-family: 'Petit Formal Script', cursive; 
    font-size: 5vw;
}

.feature2--price {
    padding-top: 2vh;
    color: var(--black);
    font-family: "Nanum Myeongjo", serif;
    font-size: 3vw;
    font-variant: thin;
}

/* End of Feature 2 stuff */



/* Footer Stuff */
.footer--content--box {
    position:static;
    margin-top:auto;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; 
    z-index: 0;
}
  
.footer--logo--image {
    position: relative;
    vertical-align: center;
    padding-right:5vw;
    display: inline-flex;
    width: 12vh;
    height:12vh;
    object-fit:cover;
    z-index: 0;
}
  
.footer--copyright {

    top: 50%;
    bottom: 50%;
    padding-left: 5vw;
    position: relative;
    vertical-align: center;
    display: inline-flex;
    color: var(--white);
    font-size: 30px;
    z-index: 0;
}
/* End footer stuff */
/* About Page */
/* Flex container for the about section */
.about--content--box {
    flex : 1;
    display: flex;
    flex-direction: column; /* Display items in a column on small screens */
    align-items: center; /* Center items horizontally */
    justify-content: flex-start;
    min-height:80vh;
    margin-top: 20vh;
    margin-bottom:10vh;
  }
  
  /* Center and crop image in a circle */
  .about--image--box {
    width: 80vw; /* Adjust the width as needed */
    height: 80vw;
    overflow: hidden;
    align-self: center; /* Align the image to the center of the flex container */
  }
  
  .about--image {
    margin-right: 10%;
    margin-left: 10%;
    width: 80%; /* Ensure the image takes the full width of the container */
    height: 80%; /* Ensure the image takes the full height of the container */
    border-radius: 50%; /* Crop the image into a circle */
    object-fit: cover;
  }
  
  /* Style for the description */
  .about--description--box {
    width: 80vw; /* Adjust the width as needed */
    font-size: 2.5vh;
    font-family: "Nanum Myeongjo", serif;
    text-align: center; /* Center text on small screens */
    margin-right: 5vw;
    margin-left: 5vw;
    line-height: 4vh;
  }

  .about--description--box a{
    padding-top: 2vh;
    color:#000000;
    text-decoration: solid;
    font-family: 'Petit Formal Script', cursive; 
  }
  
  /* Media query for small screens */
  @media only screen and (min-width: 600px) {
    .about--content--box {
      flex-direction: row; /* Display items in a row on larger screens */
      justify-content: space-between; /* Add space between the image and description */
    }
    .about--description--box {
      text-align: left; /* Reset text alignment for larger screens */
      margin-top: 2vh; /* Reset top margin for larger screens */
      width: 45vw; /* Adjust the width as needed */
      font-size: 2.5vw;
      font-family: "Nanum Myeongjo", serif;
      text-align: center; /* Center text on small screens */
      margin-right: 5vw;
      margin-left: 5vw;
      line-height: 4vw;
    }
    .about--image--box {
        width: 50vw; /* Adjust the width as needed */
        height: 50vw;
        overflow: hidden;
        align-self: center; /* Align the image to the center of the flex container */
      }
    
  }



  .contact--section {

    font-family: "Nanum Myeongjo", serif;
    background-color: var(--white);
    height:auto;
    min-height:80vh;
    min-width: 40vw;
    width:auto;
    margin-top: 8vh;
    display: flex;
    padding: 150px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 64px;
  }
  .contact--form--container {

    display: grid;
    align-items: center;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    width:auto;
    height:auto;
    min-width: 40% ;
    row-gap: 32px;
  }
  .container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 32px;
    row-gap: 32px;
  }
  .contact--label {
    font-family: "Nanum Myeongjo", serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10.6px;
    flex: 1 0 0;
    width: 100%;
  }

  .contact--input {
    display: flex;
    font-family: "Nanum Myeongjo", serif;
    padding: 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.333px solid var(--primary);
    background: var(--white);
  }


  .gallery-container {
    width: 100%;
    margin-top:20vh;
    position: relative;
    min-height: 60vh;
    display: flex;
    cursor:default;
  }
  


  .gallery-item img{
    width: min(40vh, 30vw);
    height: min(55vh, 45vw);
    cursor: pointer;
    object-fit: contain;

    transition: opacity 0.3s, width 0.3s, height 0.3s ease;
  }


.gallery-item img:hover{
    width: min(45vh, 35vw);
    height: min(60vh, 50vw);;
    opacity: 0.9;
    transition: opacity 0.3s, width 0.3s, height 0.3s ease;
  }

  .gallery-item {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    cursor: default;
    padding-bottom: 4vh;
    font-family: "Nanum Myeongjo", serif;
  }

  .gallery-item h3 {
    color:#000000;
    text-wrap:wrap;
    margin-top:2vh;
    line-height: max(4vh, 4vw);
    font-size: max(3vh, 3vw);
    font-family: "Nanum Myeongjo", serif;
    font-weight: 100;
  }

  .gallery-item p {
    color:#000000;
    line-height: max(3vh, 3vw);
    font-size: max(2vh, 2vw);
    font-family: "Nanum Myeongjo", serif;
    font-weight: 10;
  }
  .gallery {
    text-decoration: none;
    align-items: center;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: center;
    justify-content:space-around;
  }

  .item--link {
    text-decoration: none;
  }


  @media only screen and (max-width: 768px) {
    .gallery {
    
        align-items: center;
        width: 100vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-around;
      }
      .gallery-item img{
        width: 40vw;
        height: 35vh;
        cursor: pointer;
      }

      .gallery-item img:hover{
        width: 45vw;
        height: 40vh;
      }

      .gallery-item h3 {
        color:#000000;
        text-wrap:wrap;
        margin-top:0;
        line-height: max(4vh, 4vw);
        font-size: max(3vh, 3vw);
        font-family: "Nanum Myeongjo", serif;
        font-weight: 100;
      }
  }
  .gallery-sold-tag p {
    color: var(--primary);
    text-decoration: solid;
    font-weight: 800;
  }

  .commission-container {
    margin-top: 20vh;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-bottom:5vh;
  }

  .commission-container h1{
    font-stretch:extra-expanded;
    text-align: center;
    font-family: 'Petit Formal Script', cursive; 
    font-size: 6vh;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .commission-container p{
    text-align: center;
    font-weight: 10;
    font-size: 4vh;
    line-height:6vh;
    width: 50vw;
    text-wrap: wrap;
    font-family: "Nanum Myeongjo", serif;
  }

  .commission--list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .commission-container ul{
    position:relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    display: flex;
    text-align: center;
    font-weight: 10;
    font-size: 4vh;
    line-height: 0.5vh;
    font-family: "Nanum Myeongjo", serif;
    padding: 0;
  }

  .commission-container .btn-outline-primary{
    margin-top: 5vh;
    margin-bottom: 5vh;
    color:#000000;
  }
  .commission-container .btn-outline-primary:hover{
    margin-top: 5vh;
    margin-bottom: 5vh;
    color:var(--white);
  }


  .item--container{
    padding-top: 15vh;
    width: 100vw;
    min-height: 80vh;
    display:flex;
    flex-direction: row;
    padding-bottom:10vh;
  }

  .item--image--container{
    display: flex;
    align-items: center;
    justify-content: center;
    width:50vw;
  }

  .item--image{
    max-width: 35vw;
    height:45vw;
    object-fit: contain;
  }
  .item--image:hover{
    cursor:pointer;
  }
  .item-message{
    text-wrap: wrap;
    display:flex;
    flex-direction: column;
    width: 80vw;
  }
  .item--details--container{
    display: flex;
    flex-direction: column;
    columns: 1;
    align-items: center;
    justify-content: center;
    padding-top: 5vh;
    width:40vw;
    
  }

  .item--details--container h2{
    font-weight: 100;
    font-stretch:extra-expanded;
    text-align: center;
    font-family: 'Petit Formal Script', cursive; 
    font-size: min(6vw, 6vh);
  }

  .item--details--container h3{
    font-weight: lighter;
    font-stretch:extra-expanded;
    padding-top:2vh;
    padding-bottom: 4vh;
    text-align: center;
    font-family: "Nanum Myeongjo", serif;
    font-size: min(5vw, 5vh);
  }

  .item--details--container p{
    font-weight: lighter;
    text-align: center;
    line-height: 5vh;
    width:40vw;
    font-stretch:extra-expanded;
    text-align: center;
    font-family: 'Petit Formal Script', cursive; 
    font-size: min(4vw, 4vh);
    text-wrap: nowrap;
    padding-bottom: 4vh;
  }


  @media only screen and (max-width: 768px) {
    .item--container{
      padding-top: 20vh;
      width: 100%;
      min-height: 80vh;
      display:flex;
      flex-direction: column;
      align-items: center;
      padding-bottom:5vh;
    }

    .item--image{
      max-width: 90vw;
      height:100vw;
      object-fit: contain;
    }


    .item--details--container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5vh;
      width:50vw;
      
    }

    .item--image--container{
      display: flex;
      object-fit: contain;
      align-items: center;
      justify-content: center;
      width:80vw;
    }
  
    .item--details--container h2{
      font-stretch:extra-expanded;
      text-align: center;
      font-family: 'Petit Formal Script', cursive; 
      font-size: min(6vw, 6vh);
    }
  
    .item--details--container h3{
      font-weight: lighter;
      font-stretch:extra-expanded;
      padding-top:2vh;
      padding-bottom: 4vh;
      text-align: center;
      font-family: "Nanum Myeongjo", serif;
      font-size: min(5vw, 5vh);
    }
  
    .item--details--container p{
      font-weight: lighter;
      text-align: center;
      line-height: 5vh;
      width:60vw;
      font-stretch:extra-expanded;
      text-align: center;
      font-family: 'Petit Formal Script', cursive; 
      font-size: min(4vw, 4vh);
      text-wrap: nowrap;
      padding-bottom: 4vh;
    }
    .prints--description,
    .originals--description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      max-width: 80vw; /* Set a maximum width */
      width: 100%; /* Allow it to take up the full width if the screen is small */
      margin: auto; /* Center the container horizontally */
    }
    
    .prints--description p,
    .originals--description p {
      text-wrap: wrap;
      word-wrap: break-word; /* Allow long words to break into the next line */
    }
    
    .prints--description a,
    .originals--description a {
      text-decoration: none;
      background-color: var(--primary);
      color: var(--white);
      font-size: min(4vw, 4vh);
      font-family: "Nanum Myeongjo", serif;
      padding: 1em 2em; /* Adjust padding as needed */
      display: inline-block; /* Make the link a block element */
      margin-top: 1em; /* Add some space between the paragraph and the link */
    }
    
    .originals--description .btn-outline-primary{
      display:flex;
      align-items: center;
      justify-content: center;
    }

    .commission-container {

      padding: 10vw;
      margin-top: 20vh;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding-bottom:5vh;
    }

    .commission-container ul{
      position:relative;
      justify-content: center;
      align-items: center;
      align-content: center;
      align-self: center;
      display: flex;
      text-align: center;
      font-weight: 10;
      font-size: 2vh;
      line-height: 0.5vh;
      font-family: "Nanum Myeongjo", serif;
      padding: 0;
    }

    .commission-container p{
      text-align: center;
      font-weight: 10;
      font-size: 2vh;
      line-height:4vh;
      width: 50vw;
      text-wrap: wrap;
      font-family: "Nanum Myeongjo", serif;
    }



  }